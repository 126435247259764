import React, { useState, useEffect } from 'react';
import { fetchDailyData } from './../../api';
import { Line, Bar } from 'react-chartjs-2';

import styles from './Chart.module.css';

const Chart = ({data: { confirmed, recovered, deaths }, country}) => {
  const [dailyData, setDailyData] = useState([]);

  useEffect(() => {
    const fetchAPI = async () => {
      setDailyData(await fetchDailyData());
    };

    fetchAPI();
  }, [setDailyData]);

  const lineChart = (
    dailyData.length ? (
		<Line
			data={{
				labels: dailyData.map(({ date }) => date),
				datasets: [
					{
						data: dailyData.map(({ confirmed }) => confirmed),
						label: "Infected",
						borderColor: "#3333ff",
            fill: true,
            fontFamily: 'Poppins, san-serif'
          },
					{
						data: dailyData.map(({ deaths }) => deaths),
						label: "Deaths",
						backgroundColor: 'rgba(255, 0, 0, 0.5)',
            fill: true,
            fontFamily: 'Poppins, san-serif'
					},
				],
			}}
		/>
  ) : null
  );

  const barChart = (
    confirmed ? (
      <Bar 
        data={{
          labels: ['Infected', 'Recovered', 'Deaths'],
          datasets: [{
            label: 'People',
            backgroundColor: ['rgba(0, 0, 255, 0.5)','rgba(0, 255, 0, 0.5)','rgba(255, 0, 0, 0.5)'],
            data: [confirmed.value, recovered.value, deaths.value],
            fontFamily: 'Poppins, san-serif'
          }]
        }}
        options={{
          legend: { display: false },
          title: { display: true, text: `Current state in ${country}`, fontFamily: 'Poppins, san-serif'}
        }}/>
    ): null
  )

  return (
    <div className={styles.container}>
      { country ? barChart : lineChart }
    </div>
  )
}

export default Chart;