import React from 'react';
import { Cards, Chart, CountryPicker } from './components';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'
import HomeIcon from "@material-ui/icons/Home";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import styles from './App.module.css';

import { fetchData } from './api';

import logo from './images/roundcube_logo.png';

class App extends React.Component {
  state = {
    data: {},
    country: ''
  }

  async componentDidMount() {
    const fetchedData = await fetchData()

    this.setState({ data: fetchedData })
  }

  handleCountryChange = async (country) => {
    // fetch data
    const fetchedData = await fetchData(country);

    // set state
    this.setState({ data: fetchedData, country: country })
  }

  render() {
    const { data, country } = this.state;

    const theme = createMuiTheme({
			typography: {
				fontFamily: [
					"Poppins",
					"BlinkMacSystemFont",
					'"Segoe UI"',
					"Roboto",
					'"Helvetica Neue"',
					"Arial",
					"sans-serif",
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
				].join(","),
			},
		});

    return (
			<ThemeProvider theme={theme}>
				<AppBar position="static">
					<Toolbar variant="dense">
						<IconButton edge="start" color="inherit" aria-label="menu">
							<a
								className={styles.menu}
								href="https://nadiaapp.com"
								title="Nadia App"
							>
								<HomeIcon />
							</a>
						</IconButton>
						<IconButton edge="start" color="inherit" aria-label="menu">
							<a
								className={styles.menu}
								href="https://app.nadiaapp.com"
								title="Talk to a Doctor Now!"
							>
								<LocalHospitalIcon />
							</a>
						</IconButton>
						<img className={styles.logo} src={logo} alt="Nadia" />
						<Typography variant="h6" color="inherit" className={styles.title}>
							COVID-19 Tracker
						</Typography>
					</Toolbar>
				</AppBar>
				<div className={styles.container}>
					<Cards data={data} />
					<CountryPicker handleCountryChange={this.handleCountryChange} />
					<Chart data={data} country={country} />
					<div className={styles.footer}>
						&copy; Copyright 2020 EligiusHealth, Ltd. All rights reserved.
					</div>
				</div>
			</ThemeProvider>
		);
  }
}

export default App;
